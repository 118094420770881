<app-header-new *ngIf="!isOnSignInPage"></app-header-new>
<router-outlet></router-outlet>
<app-footer-new *ngIf="!isOnSignInPage"></app-footer-new>
<!-- Session Expiry Modal -->
<div class="modal fade" [config]="{backdrop: 'static', keyboard: false}" bsModal #sessionExpiryModal="bs-modal"
    tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="sessionExpiryModal">Warning!</h5>
                <button type="button" class="btn-close" (click)="sessionExpiryModal.hide()"></button>
            </div>
            <div class="modal-body mb-2">
                <div>
                    {{confirmationData}}
                </div>
            </div>
        </div>
    </div>
</div>
import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Idle } from '@ng-idle/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from './shared/services/common.service';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, AfterContentChecked {
    @ViewChild('sessionExpiryModal', { static: false }) sessionExpiryModal?: ModalDirective;
    public isOnSignInPage!: boolean;
    public env = environment;
    public isGridView = false;
    public showoverlay = false;
    idleWarningTime = 0;
    confirmationData = '';
    constructor(
        private commonservices: CommonService,
        private cdref: ChangeDetectorRef,
        private idle: Idle,
    ) {
        this.idle.onIdleStart.subscribe(() => {
            this.sessionExpiryModal?.show();
        });
    }

    ngOnInit(): void {
        if (this.env.DISABLE_CONSOLE) {
            console.log = () => { };
        }
        this.idle.onTimeoutWarning.subscribe((countdown) => {
            this.idleWarningTime = countdown;
            this.confirmationData =
                `Your session is going to expire in ${this.idleWarningTime} ${this.idleWarningTime > 1 ? 'seconds' : 'second'}.`
        })
        this.idle.onIdleEnd.subscribe(() => this.sessionExpiryModal?.hide());

        this.commonservices.showGridViewObservable.subscribe((response: boolean) => {
            this.isGridView = response;
        })
        this.commonservices.siginpageObservable.subscribe((response: boolean) => {
            this.isOnSignInPage = response;
        })
    }

    ngAfterContentChecked(): void {
        this.cdref.detectChanges();
    }


}
